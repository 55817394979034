@import "/src/styles/variables.scss";

.arrows {
	position: relative;
	margin-bottom: 20px;

	&::before {
		position: absolute;
		width: 110%;
		content: "";
		top: 0;
		left: -5%;
		margin: 0 auto;
		right: 0;
		bottom: 0;
		z-index: -1;
		clip-path: polygon(95% 20%, 100% 50%, 95% 80%, 0 80%, 5% 50%, 0% 20%);
	}

	&::after {
		position: absolute;
		content: "";
		top: 0;
		right: 0;
		bottom: 0;
		width: 70px;
		height: 70px;
		left: 0;
		margin: 0 auto;
		border-radius: 50%;
		z-index: -1;
	}

	&.arrow1 {
		&.withImage::after {
			background-color: $white;
		}

		&::before {
			background-color: $black;
		}

		&::after {
			background-color: $black;
			border: 1px black solid;
		}
	}

	&.arrow2 {
		&.withImage::after {
			background-color: $white;
		}

		&::before {
			background-color: $brandColorShadeLvl3;
		}

		&::after {
			border: 1px $brandColorShadeLvl3 solid;
			background-color: $brandColorShadeLvl3;
		}
	}

	&.arrow3 {
		&.withImage::after {
			background-color: $white;
		}

		&::before {
			background-color: #93279f;
		}

		&::after {
			background-color: #93279f;
			border: 1px #93279f solid;
		}
	}

	&.arrow4 {
		&.withImage::after {
			background-color: $white;
		}

		&::before {
			background-color: $brandColor;
		}

		&::after {
			background-color: $brandColor;
			border: 1px $brandColor solid;
		}
	}

	&.arrow5 {
		&.withImage::after {
			background-color: $white;
		}

		&::before {
			background-color: $brandColorShadeLvl0;
		}

		&::after {
			background-color: $brandColorShadeLvl0;
			border: 1px $brandColorShadeLvl0 solid;
		}
	}
}

.insideTitle {
	color: $white;
	padding: 0 !important;
}

.insideContainer {
	height: 70px;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
}

.imageContainer {
	width: 47px;
	height: 47px;
	margin: 0 auto;
}
