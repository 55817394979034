@import "/src/styles/variables.scss";

.assetContainer {
	display: flex;
	gap: $flexGap5P;
	align-items: center;

	&.right {
		flex-direction: row;
	}

	&.left {
		flex-direction: row-reverse;
	}
}

.asset {
	flex: 0 0 calc(55% - 2.5%);
}

.content {
	flex: 0 0 calc(45% - 2.5%);
}

.list__item {
	div {
		@include tagStyles();
	}

	padding: 20px 0;
	border-top: 2px #e9e9ee solid;
}

.list__item:first-child {
	border-top: none;
}

.content {
	&__container {
		padding: 30px 0;
		max-width: 55% !important;
		width: 100%;
	}

	&__description {
		padding-top: 40px;
	}
}

.listItem {
	padding-right: 25px !important;
}

@media screen and (max-width: $L) {
	.assetContainer {
		gap: $flexGap24;
	}

	.asset {
		@include flex(2, $flexGap24);
	}

	.content {
		@include flex(2, $flexGap24);
	}
}

@media screen and (max-width: $M) {
	.assetContainer {
		gap: $flexGap48;

		&.right {
			flex-direction: column-reverse;
		}

		&.left {
			flex-direction: column-reverse;
		}
	}

	.asset {
		flex: 0 0 100%;
	}

	.content {
		flex: 0 0 100%;
	}

	.content {
		&__container {
			padding: 30px 0;
			max-width: 100% !important;
		}
	}
}

.listEnter {
	overflow: hidden;
	opacity: 0;
	transition: opacity 500ms ease-in-out, max-height 500ms ease-in-out;
}

.listEnterActive {
	overflow: hidden;
	opacity: 1;
	transition: opacity 500ms ease-in-out, max-height 500ms ease-in-out;
}

.listExit {
	overflow: hidden;
	opacity: 1;
	transition: opacity 500ms ease-in-out, max-height 500ms ease-in-out;
}

.listExitActive {
	overflow: hidden;
	opacity: 0;
	transition: opacity 500ms ease-in-out, max-height 500ms;
}
