@import "/src/styles/variables.scss";

.languageSwitcher {
	position: relative;
	display: inline-block;
	width: 100%;
}

.selectedOption {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	width: 100%;
	height: 100%;
	color: $black;
	border-radius: 3px;
	border: 1px solid gray;
	cursor: pointer;

	&.active {
		border: 1px solid $grayScaleLvl1;
		border-bottom: 1px solid transparent;
		border-radius: 3px 3px 0 0;
		// padding-bottom: 11px;
	}

	&::after {
		content: "";
		position: absolute;
		z-index: 99;
		top: -2.5px;
		right: 10px;
		bottom: 0;
		margin: auto 0;
		color: $black;
		height: 5px;
		width: 5px;
		border-right: 2px solid;
		border-bottom: 2px solid;
		transform: rotate(45deg);
		transition: transform 0.2s;
	}
}

.selectedOption:hover {
	background-color: $grayScaleLvl5;
	border: 1px solid $grayScaleLvl1;

	&.active {
		border-bottom: 1px solid transparent;
	}
}

.selectMenu {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	z-index: 101;
	text-align: left;
	background-color: $white;
	border: 1px solid $grayScaleLvl1;
	border-top: none;
	border-radius: 0 0 3px 3px;
	overflow: hidden;
	transition: opacity 0.3s ease;
}

.selectMenu.show {
	opacity: 1;
}

.hide {
	display: none;
}

.option {
	padding: 10px;
	cursor: pointer;

	&.active {
		background-color: $grayScaleLvl4;
	}
}

.option:hover {
	background-color: $grayScaleLvl5;
}

.enter {
	overflow: hidden;
	opacity: 1;
	max-height: 1px;
	transition: opacity 500ms ease-in-out, max-height 500ms ease-in-out;
}

.enterActive {
	overflow: hidden;
	opacity: 1;
	max-height: 1000px;
	transition: opacity 500ms ease-in-out, max-height 500ms ease-in-out;
}

.exit {
	overflow: hidden;
	opacity: 1;
	max-height: 1000px;
	transition: opacity 200ms ease-in-out, max-height 200ms ease-in-out;
}

.exitActive {
	overflow: hidden;
	opacity: 0;
	max-height: 1px;
	transition: opacity 200ms ease-in-out, max-height 200ms;
}
