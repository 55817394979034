@import "/src/styles/variables.scss";

.overlay {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	// background-color: $black;
	z-index: 10;
}
