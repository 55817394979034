@import "/src/styles/variables.scss";

.container {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 10000;
}

.container__wrapper {
	margin: 0 auto;
	height: 100%;
	display: flex;
	position: relative;
	align-items: center;
}

.content__container {
	background-color: $white;
	max-height: 90vh;
	margin: 0 auto;
	position: relative;
	width: 100%;
}

.content__wrapper {
	margin: 0 auto;
	z-index: 100000;
	overflow-y: auto;
	max-height: 90vh;
	padding: 20px;
	position: relative;
}

.exit__button {
	position: absolute;
	top: -35px;
	right: 0;
}

// @import "/src/styles/variables.scss";

// .container {
// 	position: fixed;
// 	left: 0;
// 	top: 0;
// 	height: 100%;
// 	width: 100%;
// 	background-color: rgba(0, 0, 0, 0.7);
// 	z-index: 10000;
// 	overflow-y: scroll;
// }

// .content__container {
// 	width: 100%;
// 	height: auto;
// 	background-color: $white;
// 	margin: 100px auto;
// 	position: relative;
// }

// .content__wrapper {
// 	margin: 0 auto;
// 	z-index: 100000;
// 	padding: 20px;
// 	position: relative;
// }

// .exit__button {
// 	position: absolute;
// 	top: -35px;
// 	right: 0;
// }
