@import "/src/styles/variables.scss";

.content div div {
	@include tagStyles();
}

.content button {
	@include tagStyles();

	display: block;
}

.assetContainer {
	display: flex;
	gap: $flexGap5P;
	align-items: center;

	&.right {
		flex-direction: row;
	}

	&.left {
		flex-direction: row-reverse;
	}
}

.content_buttons_box {
	display: flex;
	gap: $flexGap24;
	padding-top: 1rem;

	& button {
		& a {
			display: block;
		}
	}
}

.asset {
	@include flex(2, $flexGap5P);
}

.content {
	@include flex(2, $flexGap5P);
}

@media screen and (max-width: $L) {
	.assetContainer {
		gap: $flexGap24;
	}

	.asset {
		@include flex(2, $flexGap24);
	}

	.content {
		@include flex(2, $flexGap24);
	}
}

@media screen and (max-width: $M) {
	.assetContainer {
		gap: $flexGap16;

		&.right {
			flex-direction: column-reverse;
		}

		&.left {
			flex-direction: column-reverse;
		}
	}

	.asset {
		flex: 0 0 100%;
	}

	.content {
		flex: 0 0 100%;
	}
}
