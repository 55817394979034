.iframeContainer {
	position: relative;
	width: 100%;
	padding-bottom: 56.25%; /* 16:9 aspect ratio */
	height: 0;
	overflow: hidden;
}

.iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
