@import "/src/styles/variables.scss";

.container {
	display: flex;
	justify-content: space-between;
	gap: $flexGap5P;
}

.assetBox {
	flex: 1;
}

.containerClassName {
	padding: 4px;
}

.contentBox {
	&.withAsset {
		flex: 0 0 45%;
	}

	&.withoutAsset {
		flex: 1;
	}

	a {
		color: $brandColor;
		transition: 0.3s;
		text-decoration: underline;
		display: inline;

		&:hover {
			color: $brandColorShadeLvl1;
			transition: 0.3s;
		}
	}
}

@media screen and (max-width: $L) {
	.container {
		flex-direction: column;
		gap: $flexGap32;
	}
}
