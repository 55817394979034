@mixin tagStyles() {
	iframe {
		aspect-ratio: 16 / 9;
		border-radius: $borderRNormal;
		height: 100%;
		width: 100%;
	}

	figure,
	blockquote {
		width: 70%;
		margin: 4rem auto;
	}

	blockquote {
		padding: 20px;
		border-top: 1px solid $black;
		border-bottom: 1px solid $black;
		text-align: center;
		color: $brandColorShadeLvl2;
	}

	blockquote {
		padding: 40px 0;
		position: relative;
		border: none;
		color: $black;
		font-style: italic;
	}

	blockquote:before {
		width: 100%;
		border-radius: $borderRFull;
		content: "";
		position: absolute;
		top: 0;
		z-index: -1;
		left: 0;
		height: 100%;
		background-color: $grayScaleLvl5;
	}

	th {
		font-weight: 600;
		background-color: $grayScaleLvl5;
		padding: 16px;
		border: 1px solid #ddd;
	}

	td {
		border: 1px solid #ddd;
		padding: 16px;
		font-weight: 400;
	}

	tr {
		&:hover {
			background-color: $grayScaleLvl5;
		}
	}

	tbody {
		tr:nth-child(even) {
			background-color: $grayScaleLvl5;

			&:hover {
				background-color: $grayScaleLvl5;
			}
		}
	}

	// Style for the p tag
	p {
		font-size: 16px;
		line-height: 1.5;
		margin-bottom: 16px;
	}

	// Style for the span tag
	span {
		font-weight: 600;
	}

	// Style for the ul tag
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	// Style for the li tag
	li {
		margin-bottom: 0.5rem;
		padding-left: 16px;
		position: relative;

		&:before {
			content: "\2022";
			color: $black;
			font-size: 1.2rem;
			position: absolute;
			left: 0;
			top: 0.16px;
		}
	}

	blockquote p {
		width: 70%;
		margin: 0 auto;
		position: relative;
		line-height: 28px;
		font-size: 20px;
		text-align: center;
	}

	blockquote p:after {
		content: "";
		position: absolute;
		top: 0;
		left: calc(-24px - 48px);
		width: 48px;
		height: 32px;
		background-repeat: no-repeat !important;
		background-size: contain !important;
		background: url(https://dreambroker.com/opening%20quotation%20mark.svg);
	}

	blockquote p:before {
		content: "";
		position: absolute;
		bottom: 0;
		right: calc(-24px - 48px);
		width: 48px;
		height: 32px;
		background-repeat: no-repeat !important;
		background-size: contain !important;
		background: url(https://dreambroker.com/closing%20quotation%20mark.svg);
	}

	img {
		width: 100% !important;
		position: relative;
		height: 100% !important;
		border-radius: $borderRNormal;
	}

	a {
		display: inline;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 2.5rem;
		margin-top: 2rem;
		margin-bottom: 16px;
		font-weight: 600;
	}

	// Style for the h1 tag
	h1 {
		font-size: 2.5rem;
	}

	// Style for the h2 tag
	h2 {
		font-size: 2rem;
	}

	// Style for the h3 tag
	h3 {
		font-size: 1.75rem;
	}

	// Style for the h4 tag
	h4 {
		font-size: 1.5rem;
	}

	// Style for the h5 tag
	h5 {
		font-size: 1.25rem;
	}

	// Style for the h6 tag
	h6 {
		font-size: 16px;
		text-transform: uppercase;
	}

	a {
		color: $brandColor;
		transition: 0.3s;
		text-decoration: underline;
		display: inline;

		&:hover {
			color: $brandColorShadeLvl1;
			transition: 0.3s;
		}
	}

	@media screen and (max-width: $L) {
		iframe {
			border-radius: $borderRFull;
		}

		figure,
		blockquote {
			width: 100%;
		}

		img {
			border-radius: $borderRFull;
		}
	}

	@media screen and (max-width: $M) {
		// Style for all heading tags
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			line-height: 26px;
			margin-top: 20px;
		}

		// Style for the h1 tag
		h1 {
			font-size: 26px;
		}

		// Style for the h2 tag
		h2 {
			font-size: 24px;
		}

		// Style for the h3 tag
		h3 {
			font-size: 22px;
		}

		// Style for the h4 tag
		h4 {
			font-size: 20px;
		}

		// Style for the h5 tag
		h5 {
			font-size: 18px;
		}

		// Style for the h6 tag
		h6 {
			font-size: 16px;
		}

		iframe {
			width: 100%;
			min-width: 168px;
			height: auto;
			min-height: 152px;
		}

		th {
			font-size: 16px;
			padding: 5px;
		}

		td {
			font-size: 16px;
			padding: 5px;
		}

		figure,
		blockquote {
			width: 100%;
			margin: 3rem auto;
		}

		img {
			width: 112% !important;
			left: -6%;
		}

		// Style for the li tag
		li {
			padding-left: 10px;
		}

		blockquote p {
			width: calc(100% - 100px);
			line-height: 24px;
			font-size: 16px;
		}

		blockquote:before {
			width: 120% !important;
			left: -10%;
		}

		blockquote p:before {
			right: calc(-24px - 24px);
			width: 36px;
			height: 24px;
		}

		blockquote p:after {
			left: calc(-24px - 24px);
			width: 36px;
			height: 24px;
		}
	}
}
